<template>
  <b-row>
    <b-col
      xs="12"
      lg="3"
      class="settings-page mb-2"
    >
      <div class="settings-page__tabs">
        <template v-for="tab in tabs">
          <BButton
            v-if="(!tab.permission ||
                  $store.getters['user/permission'](tab.permission)) &&
                  !tab.onlyAdmin ||
                  (tab.onlyAdmin && userRole.name === 'admin')"
            :key="tab.route.name"
            class="text-left"
            :to="tab.route"
            active-class="btn-primary"
            variant="flat-dark"
          >
            <feather-icon
              :icon="tab.icon"
              size="18"
              class="mr-50"
            />
            {{ t(`pageTitle.${tab.route.name}`) }}
            <b-badge
              v-if="tab.badge"
              variant="success"
            >
                {{ tab.badge }}
            </b-badge>
          </BButton>
        </template>
      </div>
    </b-col>
    <b-col
      xs="12"
      lg="9"
    >
      <div class="settings-page__content">
        <router-view />
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { BButton, BCol, BRow, BBadge } from 'bootstrap-vue';
import {computed} from '@vue/composition-api';
import { useI18n } from '@/hooks/useI18n';
import {useStore} from "@/hooks/useStore";

export default {
  components: {
    BButton,
    BCol,
    BRow,
    BBadge
  },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const userAccount = computed(() => store.getters['user/account']);
    const userRole = computed(() => store.getters['user/role'])

    const tabs = computed(() => [
      {
        icon: 'UserIcon',
        route: {
          name: 'settings-profile',
        },
      },
      {
        icon: 'BriefcaseIcon',
        route: {
          name: 'settings-company',
        },
      },
      {
        icon: 'UsersIcon',
        route: {
          name: 'settings-employee',
        },
        permission: 'users:update',
      },
      {
        icon: 'LockIcon',
        route: {
          name: 'settings-change-password',
        },
      },
      {
        icon: 'FileIcon',
        route: {
          name: 'settings-file-store',
        },
        badge: userAccount.value.file_tariff.title ?? '',
        onlyAdmin: true,
      }
      // {
      //   icon: 'BellIcon',
      //   route: {
      //     name: 'settings-notifications',
      //   },
      // },
    ]);

    return {
      t,
      tabs,
      userRole
    };
  },
};
</script>

<style lang="scss">
  .settings-page {
    display: flex;
    &__tabs {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 270px;
      @media (max-width: 600px) {
        max-width: unset;
      }
    }
  }
</style>
